import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: '/', title: 'Dashboard', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Quản trị website', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/website-manager/products', title: 'Sản phẩm', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/news', title: 'Danh mục tin', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/pages', title: 'Trang', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/posts', title: 'Bài viết', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/categories', title: 'Menu', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/blocks', title: 'Widget', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/banners', title: 'Banner quảng cáo', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/website-manager/website', title: 'Quản lí website', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
  },
  {
    path: '/website-release-bond', title: 'Tổ chức phát hành & sản phẩm', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/release', title: 'Tổ chức phát hành', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/bond', title: 'Quản lí sản phẩm', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
  },
  {
    path: '/bond-sales', title: 'Trái phiếu đang chào bán', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/customers', title: 'Khách hàng', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/contracts', title: 'Hợp đồng', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/partner-and-commission', title: 'Đối tác & hoa hồng', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/partner', title: 'Đối tác', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/branch', title: 'Chi nhánh', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/employees', title: 'Nhân viên kinh doanh', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/reports', title: 'Báo cáo', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/open-account', title: 'Mở tài khoản', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/close-account', title: 'Đóng tài khoản', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/change-info-cus', title: 'Thay đổi thông tin KH', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/change-info-authorization', title: 'Thay đổi thông tin uy quyền', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/issue-fee', title: 'Phí phát hành sơ cấp', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/owner-bond', title: 'DS người sở hữu trái phiếu', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-all', title: 'Báo cáo tổng hợp', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-commission', title: 'Chuyển nhượng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-block', title: 'Lý do phong tỏa', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-transaction', title: 'Kết quả trái phiếu', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-custody', title: 'Tình hình ĐK, LK trong kỳ', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-repurchase', title: 'Mua lại trước hẹn', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-pay', title: 'Thanh toán gốc lãi', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-cash', title: 'Tình hình TT gốc, lãi TP trong kỳ', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-interest', title: 'Thanh toán lãi', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-investors', title: 'Số lượng nhà đầu tư', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-fees', title: 'Phí chuyển nhượng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/report-tax', title: 'Thuế thu nhập cá nhân', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
  },
  {
    path: '/utilities', title: 'Tiện ích', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/send-job', title: 'Gửi email nhắc lịch CV', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/send-email', title: 'Gửi email cho khách hàng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/send-sms', title: 'Gửi sms cho khách hàng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
  },
  {
    path: '/config-system', title: 'Cấu hình hệ thống', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/config-tax', title: 'Thuế TNCN', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/interest-rank1', title: 'khoảng thời gian', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/department', title: 'Phòng ban', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dayoff', title: 'Quản lí ngày nghỉ', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/position', title: 'Chức vụ', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/blockadeReason', title: 'Phong tỏa', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/service-fee', title: 'Cấu hình phí', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
  },
  {
    path: '/banks-new', title: 'Quản lí ngân hàng', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
  },
  {
    path: '/system', title: 'Quản trị', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/functions', title: 'Chức năng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/roles', title: 'Phân quyền', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/users', title: 'Người dùng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
  },
];
