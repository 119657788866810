import { environment } from "environments/environment";

const apiUrl ="/category/";

export class ApiCategoriesConstant {
  public static LanguageList = apiUrl.concat('api/category/category-language-list');
  //#region province-list
  public static ProvinceListUrl = apiUrl.concat('api/category/category-province-list');
  public static DistrictListUrl = apiUrl.concat('api/category/category-district-list');
  public static DistrictLisByProvinceUrl = apiUrl.concat('api/category/category-district-list-province');
  public static WardListUrl = apiUrl.concat('api/category/category-ward-list');
  public static WardListByDistrictUrl = apiUrl.concat('api/category/category-ward-list-district');
  //#region province-list
  public static NationalListUrl = apiUrl.concat('api/category/category-nation-list');

  //#region unit-list
  public static UnitListUrl = apiUrl.concat('api/category/category-unit-list');
  //end unit - list

  //#region packing-list
  public static PackingListUrl = apiUrl.concat('api/category/category-packing-list');
  //end packing - list

  //#region standard-list
  public static StandardListUrl = apiUrl.concat('api/category/category-standard-list');
  //end standard - list

  //#region status-list
  public static StatusListUrl = apiUrl.concat('api/category/category-status-list-bytype');
  //end status - list

  // Category
  public static CategoryProductListUrl = apiUrl.concat('api/category-product/category-product-list');
  public static CategoryProductLisViewMenuUrl = apiUrl.concat('api/category-product/category-product-list-view-menu');
  public static CategoryProductLisViewSearchUrl = apiUrl.concat('api/category-product/category-product-list-view-search');

  public static CategoryProductByIdtUrl = apiUrl.concat('api/category-product/category-product');
  public static CategoryProductListParentUrl = apiUrl.concat('api/category-product/category-product-list-parent');
  // End Category

  public static goongMapUrlAPIAutocomplate = `https://rsapi.goong.io/Place/AutoComplete?api_key=${environment.goongMapApiKey}&input=`;
  public static goongMapUrlAPIDetail = `https://rsapi.goong.io/Place/Detail?api_key=${environment.goongMapApiKey}&place_id=`;
  public static goongMapUrlAPILocationBiasedSearch = `https://rsapi.goong.io/Place/Detail?api_key=${environment.goongMapApiKey}&location=`;

}
