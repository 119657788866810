import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener, Injector
} from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from '@angular/router';
import { customAnimations } from '../animations/custom-animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../../../shared/services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../shared/services/layout.service';
import { AppComponentBase } from 'app/shared/common/app-base-component';
import { StorageService } from 'app/shared/services/storage.service';

import { AppInitService } from 'app/services/app-init.service';
import { OrdersService } from 'app/services/orders.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: customAnimations
})
export class VerticalMenuComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  public config: any = {};
  logoUrl = 'assets/img/logo-smartgap2.svg';
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  count_order: number;
  storage_type;
  public baseUrl = this.configUrl.baseUrl.apiUrl;

  constructor(
    private configUrl: AppInitService,
    private _ordersService: OrdersService,

    injector: Injector,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private storeService: StorageService,
  ) {
    super(injector);
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
    this.storage_type = this.storeService.get('storage_type')
  }


  ngOnInit() {

    // Lấy menu từ API trả về và lưu vào LocalStorage
    // const lstMenuConfig = localStorage.getItem('menu');
    // if(lstMenuConfig && lstMenuConfig !="undefined")
    // {
    //   this.menuItems = this.getConfigMenu(JSON.parse(lstMenuConfig));
    // }

    if (this.shopType == this.ConstShopType.Nomal) {
      this.menuItems = ROUTES.filter(x => !x.isTracer);
    }
    else {
      this.menuItems = ROUTES;
    }

    // if (this.storage_type == 'true') {
    //   this.menuItems.forEach(obj => {
    //     // if (obj.title == 'MENU.OrderManager') {
    //     //   obj.submenu.push(
    //     //     { path: '/warehouse/request-warehouse-list', title: 'Yêu cầu nhập hàng trên smartGap', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //   )
    //     // }
    //   })


    // }
    
    this.orderCountNew(this.shopId);
    
  }
  getOrder() {


    this.menuItems.forEach(obj => {
      if (obj.title == 'MENU.OrderManager') {
        console.log(this.count_order);
        obj.badge =this.count_order.toString();
      }
    });
  }
  orderCountNew(id: number): void {
    this._ordersService.GetCountOrderNew(id).subscribe(rs => {
      this.count_order = rs.data;
      this.getOrder();
    })
  }
  getConfigMenu(lst) {
    const lstMenu = [];

    for (let i = 0; i < lst.length; i++) {
      const item = new Object();

      item['path'] = lst[i]['listMenus'].length ? '' : lst[i]['Url'];
      item['title'] = lst[i]['Name'];
      item['icon'] = lst[i]['Icon'] ? lst[i]['Icon'] : 'ft-align-left';
      item['class'] = lst[i]['listMenus'].length ? 'dropdown nav-item has-sub' : 'dropdown nav-item';
      item['isExternalLink'] = false;
      item['badge'] = '';
      item['badgeClass'] = '';
      item['submenu'] = lst[i]['listMenus'].length ? this.getSubMenu(lst[i]['listMenus']) : [];

      lstMenu.push(item);
    }


    return JSON.parse(JSON.stringify(lstMenu));
  }

  getSubMenu(lst) {
    const lstMenu = [];

    for (let i = 0; i < lst.length; i++) {
      const item = new Object();

      item['path'] = lst[i]['listMenus'].length ? '' : lst[i]['Url'];
      item['title'] = lst[i]['Name'];
      item['icon'] = lst[i]['Icon'] ? lst[i]['Icon'] + ' has-sub' : 'ft-align-left  has-sub';
      item['class'] = lst[i]['listMenus'].length ? 'dropdown nav-item has-sub' : 'dropdown nav-item';
      item['isExternalLink'] = false;
      item['badge'] = '';
      item['badgeClass'] = '';
      item['submenu'] = [];

      lstMenu.push(item);
    }

    return lstMenu;
  }


  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      if (this.shopType == this.ConstShopType.Nomal) {
        this.menuItems = ROUTES.filter(x => !x.isTracer);
      }
      else {
        this.menuItems = ROUTES;
      }
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-smartgap2.svg';
    } else {
      this.logoUrl = 'assets/img/logo-smartgap2.svg';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
