import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: 'root'
})
export class NotifyMessageService {

  constructor(
    private toastr: ToastrService,
    private translateService:TranslateService
  ) { }

  warning(msg): void {
    this.toastr.warning(msg, this.translateService.instant('Cảnh báo'));
  }

  success(msg): void {
    this.toastr.success(msg, this.translateService.instant('Hoàn thành'));
  }

  error(msg): void {
    this.toastr.error(msg, this.translateService.instant('Lỗi xảy ra'));
  }
}
