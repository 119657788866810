export class UserChangePass {
  UserId: number;
  PasswordOld: string;
  PasswordOldE: string;
  PasswordNew: string;
  PasswordNewE: string;
  UserName: string;
  Avatar: string;
  FullName: string;
  ConfirmPassword: string;
}
