const apiUrl =  "shop/";
//const apiUrl = "http://103.162.31.101:8012/";

export class ApiShopConstant {

  //#region Auth
  public static LoginUrl = apiUrl.concat('api/user/shop-login');
  //#endregion

  //#region shop information
  public static ShopUrl = apiUrl.concat('api/user/shop');
  public static ShopCreateUrl = apiUrl.concat('api/user/shop-create');
  public static ShopModifyUrl = apiUrl.concat('api/user/shop-modify');
  public static ListShop = apiUrl.concat('api/user/shop-list');
  //#endregion

  public static TransportProvinceListUrl = apiUrl.concat('api/user/shop-transport-province-list');


  // Product
  public static CreateProductUrl = apiUrl.concat('api/product/shop-product-create');
  public static UpdateProductUrl = apiUrl.concat('api/product/shop-product-modify');
  public static GetListProductUrl = apiUrl.concat('api/product/shop-product-list-byshop');
  public static ShopProductCategoryListByShop = apiUrl.concat('api/product/shop-product-category-listbyshop');
  public static GetListProductByShopUrl = apiUrl.concat('api/product/shop-product-list-shop');
  public static ShopProductListWarehouse = apiUrl.concat('api/product/shop-product-list-warehouse');
  public static GetProducByIdtUrl = apiUrl.concat('api/product/shop-product');
  public static DeletedProducListtUrl = apiUrl.concat('api/product/shop-product-delete-list');
  public static HideProducListtUrl = apiUrl.concat('api/product/shop-product-active-list');
  public static HideShowProductUrl = apiUrl.concat('api/product/shop-product-change-active?');
  // End Product


  //#region raw-material-area
  public static RawMaterialAreaGetListUrl = apiUrl.concat('api/user/shop-raw-material-area-list');
  public static RawMaterialAreaGetByIdUrl = apiUrl.concat('api/user/shop-raw-material-area');
  public static RawMaterialAreaCreateUrl = apiUrl.concat('api/user/shop-raw-material-area-create');
  public static RawMaterialAreaModifyUrl = apiUrl.concat('api/user/shop-raw-material-area-modify');
  public static RawMaterialAreaDeletedUrl = apiUrl.concat('api/user/shop-raw-material-area-delete');
  //#endregion

  //#region product-material
  public static ProductListByIdUrl = apiUrl.concat('api/product/list-by-ids');
  public static ProductMaterialGetListUrl = apiUrl.concat('api/product/shop-product-material-list');
  public static ProductMaterialGetByIdUrl = apiUrl.concat('api/product/shop-product-material');
  public static ProductMaterialCreateUrl = apiUrl.concat('api/product/shop-product-material-create');
  public static ProductMaterialModifyUrl = apiUrl.concat('api/product/shop-product-material-modify');
  public static ProductMaterialDeletedUrl = apiUrl.concat('api/product/shop-product-material-delete');
  //#endregion

   //#region product-material
   public static ProductionProcessGetListUrl = apiUrl.concat('api/product/shop-product-production-process-list');
   public static ProductionProcessGetByIdUrl = apiUrl.concat('api/product/shop-product-production-process');
   public static ProductionProcessCreateUrl = apiUrl.concat('api/product/shop-product-production-process-create');
   public static ProductionProcessModifyUrl = apiUrl.concat('api/product/shop-product-production-process-modify');
   public static ProductionProcessDeletedUrl = apiUrl.concat('api/product/shop-product-production-process-delete');
   //#endregion

  //#region shop-factory
  public static ShopFactoryGetListUrl = apiUrl.concat('api/user/shop-factory-list');
  public static ShopFactoryGetByIdUrl = apiUrl.concat('api/user/shop-factory');
  public static ShopFactoryCreateUrl = apiUrl.concat('api/user/shop-factory-create');
  public static ShopFactoryModifyUrl = apiUrl.concat('api/user/shop-factory-modify');
  public static ShopFactoryDeletedUrl = apiUrl.concat('api/user/shop-factory-delete');
  //#endregion

  //#region shop-store
  public static ShopStoreGetListUrl = apiUrl.concat('api/user/shop-store-list');
  public static ShopStoreGetByIdUrl = apiUrl.concat('api/user/shop-store');
  public static ShopStoreCreateUrl = apiUrl.concat('api/user/shop-store-create');
  public static ShopStoreModifyUrl = apiUrl.concat('api/user/shop-store-modify');
  public static ShopStoreDeletedUrl = apiUrl.concat('api/user/shop-store-delete');
  //#endregion

  //#region shop-store
  public static AffiliatedUnitGetListUrl = apiUrl.concat('api/user/shop-affiliated-list');
  public static AffiliatedUnitGetByIdUrl = apiUrl.concat('api/user/shop-affiliated');
  public static AffiliatedUnitCreateUrl = apiUrl.concat('api/user/shop-affiliated-create');
  public static AffiliatedUnitModifyUrl = apiUrl.concat('api/user/shop-affiliated-modify');
  public static AffiliatedUnitDeletedUrl = apiUrl.concat('api/user/shop-affiliated-delete');
  //#endregion

  //#region shop-productio-diagram
  public static ShopProductionDiagramGetListUrl = apiUrl.concat('api/user/shop-production-diagram-list');
  public static ShopProductionDiagramGetByIdUrl = apiUrl.concat('api/user/shop-production-diagram');
  public static ShopProductionDiagramCreateUrl = apiUrl.concat('api/user/shop-production-diagram-create');
  public static ShopProductionDiagramModifyUrl = apiUrl.concat('api/user/shop-production-diagram-modify');
  public static ShopProductionDiagramDeletedUrl = apiUrl.concat('api/user/shop-production-diagram-delete');
  //#endregion
  ////#endregion


  ////#region shop-productio-diagram
  public static ShopSeedMaterialGetListUrl = apiUrl.concat('api/user/shop-seed-material-list');
  public static ShopSeedMaterialGetByIdUrl = apiUrl.concat('api/user/shop-seed-material');
  public static ShopSeedMaterialCreateUrl = apiUrl.concat('api/user/shop-seed-material-create');
  public static ShopSeedMaterialModifyUrl = apiUrl.concat('api/user/shop-seed-material-modify');
  public static ShopSeedMaterialDeletedUrl = apiUrl.concat('api/user/shop-seed-material-delete');
  ////#endregion

////#region shop-productio-diagram
public static ProductCertificateGetListUrl = apiUrl.concat('api/product/shop-product-certificate-list');
public static ProductCertificateGetByIdUrl = apiUrl.concat('api/product/shop-product-certificate');
public static ProductCertificateCreateUrl = apiUrl.concat('api/product/shop-product-certificate-create');
public static ProductCertificateModifyUrl = apiUrl.concat('api/product/shop-product-certificate-modify');
public static ProductCertificateDeletedUrl = apiUrl.concat('api/product/shop-product-certificate-delete');
////#endregion

////#region shop-productio-diagram
public static TechnicalProcessingGetListUrl = apiUrl.concat('api/product/shop-product-process-engineering-list');
public static TechnicalProcessingGetByIdUrl = apiUrl.concat('api/product/shop-product-process-engineering');
public static TechnicalProcessingCreateUrl = apiUrl.concat('api/product/shop-product-process-engineering-create');
public static TechnicalProcessingModifyUrl = apiUrl.concat('api/product/shop-product-process-engineering-modify');
public static TechnicalProcessingDeletedUrl = apiUrl.concat('api/product/shop-product-process-engineering-delete');
////#endregion

////#region shop-productio-diagram
public static ProcessingLogGetListUrl = apiUrl.concat('api/product/shop-product-production-log-list');
public static ProcessingLogGetByIdUrl = apiUrl.concat('api/product/shop-product-production-log');
public static ProcessingLogCreateUrl = apiUrl.concat('api/product/shop-product-production-log-create');
public static ProcessingLogModifyUrl = apiUrl.concat('api/product/shop-product-production-log-modify');
public static ProcessingLogDeletedUrl = apiUrl.concat('api/product/shop-product-production-log-delete');
////#endregion

////#region shop-productio-diagram
public static ShopUserGetListUrl = apiUrl.concat('api/user/shop-user-list');
public static ShopUserGetByIdUrl = apiUrl.concat('api/user/shop-user');
public static ShopUserCreateUrl = apiUrl.concat('api/user/user-create');
public static ShopUserModifyUrl = apiUrl.concat('api/user/user-modify');
public static ShopUserDeletedUrl = apiUrl.concat('api/user/shop-user-delete');
public static ShopUserChangePassUrl = apiUrl.concat('api/user/shop-user-changepass');
////#endregion


////#region shop-address
public static ShopAddressGetListUrl = apiUrl.concat('api/user/shop-address-list');
public static ShopAddressGetByIdUrl = apiUrl.concat('api/user/shop-address');
public static ShopAddressCreateUrl = apiUrl.concat('api/user/shop-address-create');
public static ShopAddressModifyUrl = apiUrl.concat('api/user/shop-address-modify');
public static ShopAddressDeletedUrl = apiUrl.concat('api/user/shop-address-delete');
////#endregion
}
