const apiUrl = "/order/";

export class ApiOrdersConstant {
  public static LanguageList = apiUrl.concat('api/category/category-language-list');
  //#region Orders
  public static OrderListUrl = apiUrl.concat('api/order-list');
  public static OderListByStatusUrl = apiUrl.concat('api/order-list-bystatus');
  public static OrderByIdtUrl = apiUrl.concat('api/order');
  public static OrderCountNewUrl = apiUrl.concat('api/order-new-count-shop');
  public static ConfirmOrdertUrl = apiUrl.concat('api/order-shop-confirm');
  public static OrderStatisticalForShoptUrl = apiUrl.concat('api/order-statistical-for-shop');
  public static OrderShopListUrl = apiUrl.concat('api/revenue-reconciliation/shop-list');
  public static OrderShopChangeStatusUrl = apiUrl.concat('api/revenue-reconciliation/shop-change-status');

  
  //#end-region
}
