import { AppStatusCode, TokenEnum } from '../common/app.constants';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
        const router = this.injector.get(Router);
        const token = authService.getToken(TokenEnum.ACCESS_TOKEN) ? authService.getToken(TokenEnum.ACCESS_TOKEN) : '';
        const reqHeader = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });

        return next.handle(reqHeader).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {            
            if (err.status === 401) {
              authService.logout();
              router.navigateByUrl('/auth/login');
            }

            const error = err.message || err.statusText;

            return throwError(error);
          }),
        );
  }
}
