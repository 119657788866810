// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import jsonURL from './../assets/app-config.json';
const jsonconfig = jsonURL;
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDiqc_TQB8HOTipTMKbP050iUGgu9kPg2o",
    authDomain: "https://demo1-d151a.firebaseio.com",
    databaseURL: "https://demo1-d151a.firebaseio.com",
    projectId: "testfb-1028e",
    storageBucket: "testfb-1028e.appspot.com",
    messagingSenderId: "1030940414643",
  },
  apiUrl: jsonconfig.apiUrl, // domain development api
  ecomUrl: jsonconfig.ecomUrl, // domain development ecom
  goongMapApiKey:"U3fv4xH8H7kSZWLcAfw068V2uT2GlybudDtTjgOo"
};
