<nav style="z-index: 10 !important;"  class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
    <div class="container-fluid navbar-wrapper">
        <div class="navbar-header d-flex">
            <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center" data-toggle="collapse" (click)="toggleSidebar()">
                <i class="ft-menu font-medium-3"></i>
            </div>
            <div class="navbar-brand-center">
                <div class="navbar-header">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <div class="logo">
                                <a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                                    <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span class="text">APEX</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="title">
            KÊNH DÀNH CHO NGƯỜI BÁN
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li display="dynamic" ngbDropdown="" class="i18n-dropdown nav-item mr-2 dropdown">
                        <a aria-haspopup="true" id="dropdown-flag" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle class="dropdown-toggle nav-link d-flex align-items-center" aria-expanded="false">
                            <img src="{{flagLanguage}}" alt=""><i class="ft-chevron-down"></i>
                        </a>
                        <ul ngbDropdownMenu="" class="dropdown-menu dropdown-menu-right text-left" x-placement="bottom-left">
                            <ng-container *ngFor="let item of languageList">
                                <a href="javascript:;" (click)="switchLang(item.code)" data-language="en" class="dropdown-item">
                                    <img src="{{item.note}}" alt="flag" class="langimg mr-2">
                                    <span class="font-small-3">{{item.name}}</span></a>
                            </ng-container>

                        </ul>
                    </li>
                    <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
                        <a class="nav-link dropdown-toggle dropdown-notification p-0" id="drp-notification" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">{{badge}}</span></a>
                        <ul ngbDropdownMenu class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

                            <li class="dropdown-menu-header">
                                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                                    <div class="d-flex">
                                        <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">{{badge}} New
                      Notification</span>
                                    </div>
                                </div>
                            </li>
                            <li class="scrollable-container" [perfectScrollbar] *ngFor="let item of listNotification; let i = index">
                                <a class="d-flex justify-content-between" href="javascript:void(0)" (click)="ReadNofication(item);">
                                    <div class="media d-flex align-items-center">
                                        <div class="media-left">
                                            <div class="mr-3">
                                                <img class="avatar" src="assets/img/portrait/small/avatar-s-20.png" alt="avatar" height="45" width="45" />
                                            </div>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="m-0">
                                                <span>{{item.ActionName}}</span>
                                            </h6>
                                            <small class="noti-text">{{item.CreatedAt}}</small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="dropdown-menu-footer">
                                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                                    Read All Notifications
                                </div>
                            </li>
                        </ul>
                    </li> -->
                    <li class="dropdown nav-item mr-3" placement="bottom-left" display="static" ngbDropdown>
                        <a class="dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
                            <div class="user d-md-flex d-none mr-2">
                                <span class="text-right text-fullName">{{username}}</span>
                                <!-- <span class="text-right text-muted text-status font-small-3">{{status ? 'Available' : 'Unavailable'}}</span> -->
                            </div>
                            <img class="avatar" src="/assets/img/user.png" height="30" width="30" />

                        </a>
                        <div class="dropdown-menu text-left dropdown-menu-right m-0 p-2" aria-labelledby="dropdownBasic2" ngbDropdownMenu>
                            <a class="dropdown-item" routerLink="/shop-config/account">
                                <div class="d-flex align-items-center">
                                    <i class="ft-edit mr-2"></i><span>Thông tin cá nhân</span>
                                </div>
                            </a>
                            <a class="dropdown-item" routerLink="/shop-config/change-pass">
                                <div class="d-flex align-items-center">
                                    <i class="ft-lock mr-2"></i><span>Đổi mật khẩu</span>
                                </div>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" routerLink="/auth/login">
                                <div class="d-flex align-items-center" (click)="logOut()">
                                    <i class="ft-power mr-2"></i><span>Đăng xuất</span>
                                </div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>





</nav>
