import { AppInterceptor } from './shared/interceptors/app.interceptor';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { QRCodeModule } from 'angularx-qrcode';
import { AppRoutingModule } from './routes/app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { CookieService } from 'ngx-cookie-service';
import { CacheService } from './shared/services/cache.service';
import { DataService } from './shared/services/data.service';
import { StorageService } from './shared/services/storage.service';
import { UtilsService } from './shared/services/utils.service';
import { AuthService } from './services/auth.service';
import { FormsModule } from '@angular/forms';
import { LoaderService } from './shared/services/loading.service';
import { NotifyMessageService } from './shared/services/notify-message.service';
import { environment } from 'environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FirestoreService } from './shared/services/firestore.service';
// import { ProductService } from './services/product.service';
// import { LocationService } from './services/location.service';
// import { CategoriesService } from './services/categories.service';
// import { RawMaterialAreaService } from './services/traceability/raw-material-area.service';
// import { ShopFactoryService } from './services/traceability/shop-factory.service';
// import { ProductMaterialService } from './services/traceability/product-material.service';
// import { ProductionProcessService } from './services/traceability/production-process.service';
// import { ShopStoreService } from './services/traceability/shop-store.service';
// import { ProductionDiagramService } from './services/traceability/production-diagram.service';
// import { SeedMaterialService } from './services/traceability/shop-seed-material.service';
// import { ProductCertificateService } from './services/traceability/product-certificate.service';
// import { TechnicalProcessingService } from './services/traceability/technical-processing.service';
// import { ProcessingLogService } from './services/traceability/processing-log.service';
// import { ShopUserService } from './services/traceability/shop-user.service';
// import { ShopInfoService } from './services/shop-info/shop-info.service';
// import { AffiliatedUnitService } from './services/traceability/affiliated-unit.service';
// import { ShopAddressService } from './services/shop-info/shop-address.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AppInitService } from './services/app-init.service';
import vi from '@angular/common/locales/vi';
registerLocaleData(vi);
//import { OrderDetailComponent } from './components/order-manager/order-detail/order-detail.component';
//import { OrdersService } from './services/orders.service';
export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ContentLayoutComponent,
    //PrintOrderComponent,
    //OrderDetailComponent,
    // MarketingChannelComponent,
    // VoucherComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    QRCodeModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA8vqktVPxULbHbDu98_m02Abm1l1vi6J0'
    }),
    PerfectScrollbarModule,
    SharedModule,
  ],
  providers: [
    AuthGuard,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    DataService,
    CacheService,
    CookieService,
    StorageService,
    CookieService,
    UtilsService,
    AuthService,
    AuthGuard,
    DatePipe,
    NgxSpinnerService,
    LoaderService,
    NotifyMessageService,
    FirestoreService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
  }

  // ngDoBootstrap() {
  //   const auth = createCustomElement(LoginComponent, { injector: this.injector });
  //   customElements.define('login', auth);
  // }
}
