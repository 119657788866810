import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AppInitService {
  public baseUrl = {
    apiUrl: "https://api-ecom-dev.smartgap.vn/",
    storageUrl: "https://api-common-dev.smartgap.vn/",
    ecomUrl: "https://ecom-dev.smartgap.vn/"
  };

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public init() {
    return from(
      fetch("assets/config/configuration.json").then((response) =>
        response.json()
      )
    )
      .pipe(
        map((config) => {
          this.baseUrl = config;
          return config;
        })
      )
      .toPromise();
  }
}
