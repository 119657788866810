import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiCategoriesConstant } from "app/shared/common/api-category.constants";
import { ApiOrdersConstant } from "app/shared/common/api-order.constants";
import { Constant } from "app/shared/common/app.constants";
import { CookieProvider } from "app/shared/providers/cookie.provider";
import { DataService } from "app/shared/services/data.service";
import { StorageService } from "app/shared/services/storage.service";
import { SearchOrders } from "app/viewModels/orders/orders-model";
import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppInitService } from "./app-init.service";

@Injectable({
    providedIn: 'root'
})
export class OrdersService {
    langPram: string;
    public baseUrl = this.config.baseUrl.apiUrl;
    constructor(
        private router: Router,
        private dataService: DataService,
        private storeService: StorageService,
        private config: AppInitService
    ) {
        this.langPram = "?language_code=" + this.storeService.get(Constant.LANG_ID);
    }
    public GetList(search: SearchOrders): Observable<any> {
        return this.dataService.post(this.baseUrl + ApiOrdersConstant.OrderListUrl, search).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }
    public OderListByStatus(search: SearchOrders): Observable<any> {
        return this.dataService.post(this.baseUrl + ApiOrdersConstant.OderListByStatusUrl, search).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }
    public GetById(id: number): Observable<any> {
        return this.dataService.get(this.baseUrl + ApiOrdersConstant.OrderByIdtUrl + '?id=' + id).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }
    public GetCountOrderNew(id: number): Observable<any> {
        return this.dataService.get(this.baseUrl + ApiOrdersConstant.OrderCountNewUrl + '?shop_id=' + id).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }
    public ConfirmOrder(id: number, status: number): Observable<any> {
        return this.dataService.get(this.baseUrl + ApiOrdersConstant.ConfirmOrdertUrl + '?order_id=' + id + '&status_id='+status).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }

    public orderStatisticalForShoptUrl(data): Observable<any> {
        return this.dataService.post(this.baseUrl + ApiOrdersConstant.OrderStatisticalForShoptUrl,data).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }

    public OrderShopListUrl(search: SearchOrders): Observable<any> {
        return this.dataService.post(this.baseUrl + ApiOrdersConstant.OrderShopListUrl, search).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }

    

    public OrderShopChangeStatusUrl(search: SearchOrders): Observable<any> {
        return this.dataService.post(this.baseUrl + ApiOrdersConstant.OrderShopChangeStatusUrl, search).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
            }),
        )
    }

}
