export enum TokenEnum {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export class StorageOption {
  public static Cookie = 'Cookie';
  public static LocalStorage = 'LocalStorage';
}

export class Constant {
  public static SessionId = 'SessionId';
  public static LANG_ID = 'lang';
  public static AppResource = {
    //#region Common
    ACTION_SUCCESS: 'Success!',
    NOT_FIND_DATA: 'No data! Please retry again.',
    ADD_SUCCESS: 'Create successfully!',
    UPDATE_SUCCESS: 'Update successfully!',
    EXCEPTION_UNKNOWN: 'Unknown exception!',
    NOT_FOUND: 'Not found in System!',
    UNAUTHORIZE: 'Unthorize! No right.',
    WARNING_UPDATING: 'Warning! This function is updating.',
    //#endregion
  };

  public static PAGE_NUMBER = 0;
  public static PAGE_SIZE = 20;
  public static MAX_VALUE = 0;
  public static DateFormat = 'DD/MM/YYYY';
}

export class AppStatusCode {
  public static StatusCode200 = 200;
  public static StatusCode201 = 201;
  public static StatusCode400 = 400;
  public static StatusCode401 = 401;
  public static StatusCode422 = 422;
  public static StatusCode500 = 500;
}
export class PagingConstant {
  public static page_number = 0;
  public static page_size = 10;
}

export enum SHIPING_TYPE {
  FAST = 1,
  EXPRESS = 2
}
export enum SHIPING_COMPANY_NAME {
  GIAOGIANGTIETKIEM = 2,
  GRAP = 1
}
export enum PAYMENT_TYPE {
  COD = 0,
  VNPAY = 1,
}
export enum PAYMENT_STATUS {
  CHUATHANHTOAN = 0,
  CHOTHANHTOAN = 1,
  THANHTOANLOI = 5,
  DATHANHTOAN_CHUAXACNHAN = 10,
  DAXACNHAN = 20,
  DOIXOAT = 30,
  HOANTHANH = 50
}
export enum SHOP_ADDRESS_TYPE {
  DEFAULT = 1,
  WAREHOUSE = 2,
  HEADQUATER = 3
}

export class StatusActive {
  public static active = true;
  public static notActive = false;
}


export enum STATUS_TYPE {
  Category_Bank = 1,
  Category_Language = 3,
  Category_Packing = 4,
  Category_Product = 5,
  Category_Province = 6,
  Category_Standard = 7,
  Category_District = 8,
  Category_Unit = 9,
  Category_Ward = 10,
  Shop_Product = 11,
  Category_Area = 12,
  Category_Nation = 13,
  RequestShop = 14,
  Category_Link = 15,
  Shop = 16,
  Category_Shipping_Unit = 17,
  Category_Type_Work = 18,
  banner = 19
}

export enum TYPE_OF_WORK {
  Action = 1,
  Transaction = 2
}
export enum TYPE_OF_TRANSACTION {
  Buy = 1,
  Sell = 2
}

export const TypeWarehouse = [
  {
    id: 1,
    name: "Kho ảo"
  },
  {
    id: 2,
    name: "Kho thực tế"
  }
]

export const discount_type = [{
  id: 0,
  name: "Giảm giá theo %"
}, {
  id: 1,
  name: "Giảm thẳng"
}, {
  id: 2,
  name: "Đồng giá"
}]

export const StatusShopProductFlashSale = [
  {
    id: 4,
    name: "Đã đăng ký",
    color: "blue"
  },
  {
    id: 0,
    name: "Đang chờ duyệt",
    color: "orange"
  },
  {
    id: 1,
    name: "Đã được duyệt",
    color: "green"
  },
  {
    id: 2,
    name: "Đã từ chối",
    color: "red"
  },
]

export enum FLASHSALE_STATUS_CODE {
  upcoming = 0,
  Registering = 1,
  Pending = 2,
  Happening=  3,
  Finished = 4
}

export const FLASHSALE_STATUS = [
  {
    id: 0,
    name: "Sắp diễn ra",
    color: "orange",
    status_code: 0,
  },
  {
    id: 1,
    name: "Đang đăng ký",
    color: "blue",
    status_code: 1,
  },
  {
    id: 2,
    name: "Chờ duyệt",
    color: "orange",
    status_code: 2,
  },
  {
    id: 3,
    name: "Đang diễn ra",
    color: "green",
    status_code: 3,
  },
  {
    id: 4,
    name: "Đã kết thúc",
    color: "red",
    status_code: 4,
  },
]

export const statusWarehouseRequest = [
  {
    id: 0,
    name: "Chưa xử lý"
  },
  {
    id: 1,
    name: "Đồng ý"
  },
  {
    id: 2,
    name: "Từ chối"
  },
  {
    id: 3,
    name: "Hàng đã về kho"
  }
]
export enum TYPE_SHOP {
  Nomal = 0,
  Tracer = 1
}

export const VOUCHER_TYPE = [
  {
    id: 0,
    name: "Mã giảm giá Toàn Shop"
  },
  {
    id: 1,
    name: "Mã giảm giá ngành hàng"
  },
  {
    id: 2,
    name: "Mã giảm giá Shop"
  },
  {
    id: 3,
    name: "Mã giảm giá sản phẩm"
  }
]

export const VOUCHER_STATUS = [
  {
    id: 0,
    name: "Đang xét duyệt"
  },
  {
    id: 1,
    name: "Đang áp dụng"
  },
  {
    id: 2,
    name: "Đã từ chối"
  }
]

export const DISCOUNT_TYPE = [
  {
    id: 0,
    name: "Chương trình giảm giá Toàn Shop"
  },
  {
    id: 1,
    name: "Chương trình giảm giá ngành hàng"
  },
  {
    id: 2,
    name: "Chương trình giảm gía Shop"
  },
  {
    id: 3,
    name: "Chương trình giảm gía sản phẩm"
  }
]

export const DISCOUNT_STATUS = [
  {
    id: 0,
    name: "Đang xét duyệt"
  },
  {
    id: 1,
    name: "Đang áp dụng"
  },
  {
    id: 2,
    name: "Đã từ chối"
  }
]

export enum PRODUCT_STATUS {
  NEW = 0, // Chưa xử lý
  APPROVALED = 1, // Đã duyệt
  REJECT = 2 // Không duyệt
}

export const STATUS_PRODUCT = [
  {
    value: null,
    label: "Tất cả"
  },
  {
    value: true,
    label: "Đang hoạt động"
  },
  {
    value: false,
    label: "Ngừng kinh doanh"
  }
]

// export enum ORDER_STATUS {
//   SHOP_CANCEL=-1,
//   WAIT_CONFIRM = 1, // Chờ xác nhận
//   WAIT_FOR_SHIPPING = 2, // Chờ giao hàng
//   SHIPPING = 3, // Đang giao hàng
//   DELIVERED = 4, // Đã giao hàng
//   ORDER_CANCEL = 5, // Đơn hủy
//   ORDER_RETURN = 6 // Trả hàng/hoàn tiền
// }

export enum ORDER_STATUS {
  HUY_DON_HANG=-1,
  CHUA_XAC_NHAN = 1, // Chờ xác nhận
  DA_XAC_NHAN = 2, // Chờ giao hàng
  DA_LAY_HANG = 3, // Đang giao hàng
  DA_DIEU_PHOI_GIAO_HANG = 4, //
  DA_GIAO_HANG = 5, //
  DA_DOI_SOAT = 6, // Trả hàng/hoàn tiền
  KHONG_LAY_DUOC_HANG=7,//KHông lấy dc hàng
  HOAN_LAY_HANG=8,//Hoàn lấy hàng
  KHONG_GIAO_DUOC_HANG=9,//KHông giao dc hàng
  DELAY_GIAO_HANG =10,//delay giao hàng
  DA_DOI_SOAT_CONG_NO_TRA_HANG=11,//
  DA_DIEU_PHOI_LAY_HANG=12,
  DON_HANG_BOI_HOAN=13,
  DANG_TRA_HANG=20,
  DA_TRA_HANG=21
}


export const STATUS_TYPE_LIST = [
  {
    id: 1,
    name: "Hiển thị"
  },
  {
    id: 0,
    name: "Ẩn"
  }
]

export const STATUS_DIARY_LIST = [
  {
    id: 0,
    name: "Hiển thị"
  },
  {
    id: 1,
    name: "Ẩn"
  },
]
