import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReqLogin } from 'app/viewModels/auth/req-login';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiShopConstant } from '../shared/common/api-shop.constants';
import { AppStatusCode, Constant, TokenEnum } from '../shared/common/app.constants';
import { CookieProvider } from 'app/shared/providers/cookie.provider';
import { StorageService } from 'app/shared/services/storage.service';
import { DataService } from 'app/shared/services/data.service';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl: string;
  public baseUrl = this.config.baseUrl.apiUrl;
  
  constructor(private router: Router,
    private dataService: DataService,
    private storeService: StorageService,
    private cookieService: CookieProvider,
    private config: AppInitService
  ) {
  }

  public getToken(token: TokenEnum) {
    return this.cookieService.get(token);
  }

  public signIn(request: ReqLogin): Observable<any> {
    return this.dataService.post(this.baseUrl + ApiShopConstant.LoginUrl, request).pipe(
      map((res: any) => {
        if (res.statusCode === AppStatusCode.StatusCode200) {
          this.setStoreToken(res['data']['token']);
          this.storeUserInfor(res['data']);
          if (this.cookieService.get(Constant.LANG_ID)) {

          } else {
            this.cookieService.set(Constant.LANG_ID, 'vi');
          }

          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
            this.redirectUrl = null;
          } else {
            this.router.navigate(['/']);
          }

        }

        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);

        return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
      }),
    )
  }

  // public refreshToken(): Observable<any> {
  // const token: Token = {
  //   accessToken: this.getToken(TokenEnum.ACCESS_TOKEN),
  //   refreshToken: this.getToken(TokenEnum.REFRESH_TOKEN)
  // };
  // return this.http.post<Token>(refreshTokenApiUrl, token).pipe(
  //   tap((data: Token) => {
  //     this.storeToken(data);
  //     return data;
  //   }),
  //   catchError(err => {
  //     return throwError(err);
  //   })
  // );
  // }

  public setStoreToken(accessToken: string, refreshToken?: string): void {
    this.cookieService.set(TokenEnum.ACCESS_TOKEN, accessToken);

    if (refreshToken) {
      this.cookieService.set(TokenEnum.REFRESH_TOKEN, refreshToken);
    }
  }

  // public decodeJwtToken(token): any {
  //   return jwt_decode(token);
  // }

  public storeUserInfor(data): void {
    console.log(data)
    this.storeService.set('id',data.id);
    this.storeService.set('type', data.type);
    this.storeService.set('shop_id', data.id);
    this.storeService.set('username', data.username);
    this.storeService.set('userid', data.userid);
    this.storeService.set('company_name', data.company_name);
    this.storeService.set('storage_type',data.storage_type);
    //this.storeService.set('fullName', data.fullName);
    this.storeService.set('url_avatar', data.path);
    // this.storeService.set('languageId', data.languageId);
    // this.storeService.set('address', data.address);
    // this.storeService.set('websiteId', data.websiteId);
    // this.storeService.set('phone', data.phone);
    // this.storeService.set('email', data.email);
    // this.storeService.set('status', data.status);
    // this.storeService.set('access_key', data.access_key);
    // this.storeService.set('menu', JSON.stringify(data['listMenus']));
  }

  public logout(): void {
    this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
    this.storeService.clear();
    this.router.navigate(['/auth/login']);
  }
}
