import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, HostListener, Injector } from '@angular/core';
import { LayoutService } from '../../../shared/services/layout.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfigService } from '../../../shared/services/config.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../../../shared/data/template-search';
import { Router } from '@angular/router';
import { CookieProvider } from 'app/shared/providers/cookie.provider';
import { StorageOption, TokenEnum } from 'app/shared/common/app.constants';
import { StorageService } from 'app/shared/services/storage.service';
import { Paging } from 'app/viewModels/base-query';
import { FirestoreService } from 'app/shared/services/firestore.service';
import { HttpClient } from '@angular/common/http';
import { UserChangePass } from 'app/viewModels/user/user-change-pass';
import { TranslateService } from '@ngx-translate/core';
import { AppComponentBase } from 'app/shared/common/app-base-component';
import { LanguageService } from 'app/services/language.service';
import { AppInitService } from 'app/services/app-init.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
  currentLang:string;
  selectedLanguageText:string;
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = './assets/img/logo-smartgap.svg';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  fullName: string;
  username: string;
  avatar: string;
  status: boolean;
  isTogged: boolean;
  currentMessage = new BehaviorSubject(null);
  badge: number;
  returnUrl: string;
  listNotification = [];
  userChangePass: UserChangePass = new UserChangePass();
  AppDomain = this.configApp.baseUrl.apiUrl;
  flagLanguage: string;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();
  languageList:any;
  public config: any = {};
  public paging: Paging;

  constructor(
    injector: Injector,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieProvider,
    private storeService: StorageService,
    public firestore: FirestoreService,
    private http: HttpClient,
    public translate: TranslateService,
    private languageService:LanguageService,
    private configApp: AppInitService
    // private angularFireMessaging: AngularFireMessaging,
  ) {
    super(injector);
    if (localStorage.getItem('lang')) {
      this.currentLang = localStorage.getItem('lang');
      this.flagLanguage = this.currentLang == "vi" ? "./assets/img/flags/vi.png" : "./assets/img/flags/us.png";
    } else {
      this.flagLanguage = this.currentLang == "vi" ? "./assets/img/flags/vi.png" : "./assets/img/flags/us.png";
    }

    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging: AngularFireMessaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )

    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 100;
    this.paging.query = '1=1';
    this.paging.order_by = '';
    this.paging.item_count = 0;

    this.userChangePass.UserId = parseInt(localStorage.getItem('id'));
    this.userChangePass.UserName = localStorage.getItem('username');
    this.userChangePass.Avatar = localStorage.getItem('avata');
    this.userChangePass.FullName = localStorage.getItem('username');
    this.status = localStorage.getItem('status') === '1' ? true : false;
    this.avatar = localStorage.getItem('avatar');


    //this.isTogged = localStorage.getItem('isTogged') === 'true' ? true : false;

  }

  ngOnInit() {
    this.listLanguage();
    this.flagLanguage=this.translate.currentLang!="vi"?"./assets/img/flags/us.png":"./assets/img/flags/vi.png";
    this.listItems = LISTITEMS;
    //this.layoutService.toggleNotiSidebar$

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    this.storeService.initialize(StorageOption.LocalStorage);
    this.fullName = this.storeService.get('full_name');
    this.username = this.storeService.get("username");
    console.log(this.username);

    //this.GetListNotification();
    // this.angularFireMessaging.messages.subscribe(
    //   (payload) => {
    //     //console.log("new message received. ", payload);
    //     this.currentMessage.next(payload);
    //     //
    //     //console.log(payload);
    //     //console.log(payload["data"]);
    //     if (payload["data"] != undefined) {
    //       this.badge = payload["data"]["badge"];
    //       this.GetListNotification();
    //       //console.log(this.badge);
    //     }
    //     this.GetListNotification();
    //   })
    // this.broadCastService.sendBroadCast('5');
    //this.getBadge();
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  GetListNotification() {
    this.paging.query += ' AND Type=4';
    this.http.get(this.configApp.baseUrl.apiUrl + '/action/user/' + this.userChangePass.UserId + '?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by).subscribe(
      (res) => {
        if (res['meta']['error_code'] == 200) {
          this.listNotification = res['data'];
          // this.badge = res["metadata"];
          this.paging.item_count = res['metadata'];

        }
      },
      (err) => {
        console.log('Error: connect to API');
      }
    );
  }

  ReadNofication(item) {
    if (item.Status != 2) {
      this.http.post(this.configApp.baseUrl.apiUrl + '/action/readNotification/' + this.userChangePass.UserId + '/' + item.ActionId, null).subscribe(
        (res) => {
          if (res['meta']['error_code'] == 200) {
            this.RouterLink(item);
          }
        },
        (err) => {
          console.log('Error: connect to API');
        });
    } else {
      this.RouterLink(item);
    }
  }

  RouterLink(item) {

    if (item.TargetId != undefined) {
      this.returnUrl = '';
      if (item.TargetType == 'CUSTOMER') {
        this.returnUrl = '/customer?cus=' + item.TargetId;
      } else if (item.TargetType == 'CONTRACT') {
        this.returnUrl = '/contracta?con=' + item.TargetId;
      } else if (item.TargetType == 'EMPLOYEE') {
        this.returnUrl = '/employee?emp=' + item.TargetId;
      } else {
        this.returnUrl = '/dashboard';
      }

      this.router.navigateByUrl(this.returnUrl);
    }
  }
  getBadge() {
    this.firestore.getBadge(this.userChangePass.UserId).subscribe((res) => {
      if (res.payload.data() != undefined) {
        this.badge = res.payload.data()['countNotification'];
        //this.GetListNotification();
      }

    },
      (err) => {
        console.log('Error: connect to API:' + err);
      });
  }

  UpdateStatus(item) {
    item.Status = 2;
    this.paging.query += ' AND Type=4';
    // this.http.get(domainApi + '/action/user/' + this.userChangePass.UserId + '?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by).subscribe(
    //   (res) => {
    //     if (res['meta']['error_code'] == 200) {
    //       this.listNotification = res['data'];
    //       // this.badge = res["metadata"];
    //       this.paging.item_count = res['metadata'];

    //     }
    //   },
    //   (err) => {
    //     console.log('Error: connect to API');
    //   }
    // );
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo-smartgap.svg';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }
  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logOut() {
    this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
    this.storeService.clear();
    this.router.navigate(['/auth/login']);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
    // this.Toggle=true?this.Toggle==false:true;
    if (lang == "vi") { this.flagLanguage = "./assets/img/flags/vi.png"; }
    else this.flagLanguage = "./assets/img/flags/us.png";
    //console.log(this.translateService.currentLang);
    //this.router.navigate(["/"]);
    window.location.reload();
  }
  listLanguage(){
    let currentLang =this.translate.currentLang;
    this.languageService.getListLanguage().subscribe(rs=>{
      this.languageList =rs.data.filter(d=>d.code !=currentLang);
    })
    }
}
