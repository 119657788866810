export class Paging {
  page: number;
  page_size: number;
  query: string;
  order_by: string;
  item_count: number;
  select: string;
  DateStart: any;
  DateEnd: any;
  status?: number;
}

export class CheckRole {
  View: boolean;
  Create: boolean;
  Update: boolean;
  Delete: boolean;
}

