import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiCategoriesConstant } from 'app/shared/common/api-category.constants';

import { DataService } from 'app/shared/services/data.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public baseUrl = this.config.baseUrl.apiUrl;
  constructor(private dataService:DataService,
    private config: AppInitService) { }
  //List request shop tracebility
  public getListLanguage(): Observable<any> {
    return this.dataService.get(this.baseUrl + ApiCategoriesConstant.LanguageList).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
      }),
    )
  }

}
