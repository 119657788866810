import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogService } from "../services/confirm-dialog.service";
import { NotifyMessageService } from "../services/notify-message.service";
import { PagingConstant, TYPE_SHOP } from "./app.constants";
import { AlertDialogService } from './../services/alert-dialog.service';

@Component({
    template: '',
})
export abstract class AppComponentBase implements OnInit, OnDestroy {
    public translate: TranslateService;
    public spinnerLoader: NgxSpinnerService;
    public confirmDialog: ConfirmDialogService;
    public alertDialog: AlertDialogService;
    public notifyMessage: NotifyMessageService;
    public shopId: number;
    public userId: number;
    public shopType: any;
    public storageType:any;
    public ConstShopType: typeof TYPE_SHOP = TYPE_SHOP;
    public company_name:string;
    public page?: number = 1;
    public page_size = PagingConstant.page_size;
    public totalRows: number;
    public url_avatar: any;
    constructor(
        injector: Injector
    ) {
        this.alertDialog = injector.get(AlertDialogService);
        this.confirmDialog = injector.get(ConfirmDialogService);
        this.spinnerLoader = injector.get(NgxSpinnerService);
        this.translate = injector.get(TranslateService);
        this.notifyMessage = injector.get(NotifyMessageService);
        this.translate.addLangs(['vi', 'en']);
        if (localStorage.getItem('lang')) {
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        }
        else {
            this.translate.setDefaultLang('vi');
            localStorage.setItem('lang', 'vi');
        }
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(localStorage.getItem('lang'));
        if (localStorage.getItem('shop_id')) {
            this.shopId = Number(localStorage.getItem('shop_id'));
        }
        if (localStorage.getItem('userid')) {
            this.userId = Number(localStorage.getItem('userid'));
        }
        if (localStorage.getItem('type')) {
            this.shopType = localStorage.getItem('type');
        }
        if (localStorage.getItem('company_name')) {
            this.company_name = localStorage.getItem('company_name');
        }

        if (localStorage.getItem('url_avatar')) {
          this.url_avatar = localStorage.getItem('url_avatar') || "";
      }

        if(localStorage.getItem('storage_type')){
            this.storageType = localStorage.getItem('storage_type');
        }
    }

    ngOnInit() {

    }
    ngOnDestroy(): void {

    }

}
