import { RouteInfo } from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '', title: 'Dashboard', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  // },
  {
    path: '', title: 'MENU.ShopConfig', icon: 'icon-setting-shop', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,isTracer: false,
    submenu: [
      { path: '/shop-config/address', title: 'Địa Chỉ', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/shop-config/profile', title: 'Hồ sơ shop', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/shop-config/account', title: 'Tài khoản', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/product/list', title: 'MENU.ProductManager', icon: 'icon-san-pham', class: 'badge some-class ', badge: '', badgeClass: '', isExternalLink: false,isTracer: false,
    submenu: [
      // { path: '/product/list', title: 'Tất cả sản phẩm', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/product/create', title: 'Thêm sản phẩm', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/product/violated-list', title: 'Sản phẩm vi phạm', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
  },
  
  {
    path: '/order-manager/order-all/0', title: 'MENU.OrderManager', icon: 'icon-don-hang', class: '', badge: '', badgeClass: 'badgeorder', isExternalLink: false,isTracer: false,
    submenu: [
     
    ],
  },
  
  {
    path: '/shop-tracer/shop-user/permission', title: 'Quản lý tài khoản', icon: 'icon-user', class: 'dropdown nav-item ', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/raw-material-area/list', title: 'MENU.RawMaterialArea', icon: 'icon-map', class: 'dropdown nav-cart', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/factory/list', title: 'Xưởng sản xuất', icon: 'icon-map', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/production-process/list', title: 'MENU.ProductProcessings', icon: 'icon-quy-trinh-sx', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/processing-log/list', title: 'MENU.ProcessHistories', icon: 'icon-quy-trinh-sx', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/technical-processing/list', title: 'MENU.TechnicalProcesses', icon: 'icon-quy-trinh-sx', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/seedling-raw-material/list', title: 'MENU.Seedlings', icon: 'icon-con-giong', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: 'shop-tracer/product-certificate/list', title: 'MENU.Certificates', icon: 'icon-reward', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
      // { path: '/shop-tracer/product-certificate/create', title: 'Thêm chứng chỉ', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: 'shop-tracer/product-certificate/list', title: 'Chứng chỉ đã tạo', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/shop-tracer/product-material/list', title: 'MENU.ProductMaterial', icon: 'icon-vat-tu-sx', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/shop-store/list', title: 'MENU.SaleRooms', icon: 'icon-store', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/shop-affiliated/list', title: 'MENU.Affliated', icon: 'icon-chat', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '/shop-tracer/shop-comment/list', title: 'MENU.Comments', icon: 'icon-chat', class: 'dropdown nav-item', badge: '', badgeClass: '', isExternalLink: false, isTracer: true,
    submenu: [
    ]
  },
  {
    path: '', title: 'MENU.Finance', icon: 'icon-tai-chinh', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/transaction/list', title: 'Đối soát', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/report/close-account', title: 'Doanh thu', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/report/change-info-cus', title: 'Số dư tài khoản Smartgap', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/report/change-info-authorization', title: 'Nạp Smart xu', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/shop-bank/list', title: 'Tài khoản ngân hàng', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'MENU.Marketing', icon: 'icon-tag-1', class: 'dropdown nav-item has-sub', isExternalLink: false,isTracer: false,
    submenu: [
      { path: '/marketing/channel', title: 'Kênh Marketing', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/contracts/transfer_contracts', title: 'Quảng cáo Smartgap', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/voucher/voucher-list', title: 'Mã giảm giá của tôi', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/discount/discount-list', title: 'Chương trình giảm giá', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
  },

 
];
